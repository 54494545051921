.App {
  /* other CSS properties */
  margin: auto;
  margin-top: 10px;
  width: 600px;
  padding: 10px;
  color: #1b3e59;
  border-radius: 10px;
  box-shadow: 8px 8px 16px 2px #1b3e59;
  background-color: rgba(248, 203, 3, 0.482);
}

.App input {
  width: 90%;
  padding: 10px;
  border: 1px solid #1b3e59;
  border-radius: 5px;
  box-shadow: 4px 4px 4px 2px #1b3e59;
  margin-bottom: 25px;
}

.App label {
  /* other CSS properties */
  font-size: 20px;
  color: #1b3e59;
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
}
body {
  background-color: lightgrey;
  color: #1b3e59;
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App button {
  box-shadow: 2px 2px 2px;
}
.App h1 {
  text-align: center;
  color: #1b3e59;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
}
form {
  box-sizing: border-box;
  margin-left: 25px;
}
